import PropTypes from 'prop-types';
import React from 'react';
import { ButtonToolbar } from 'reactstrap';

import {
  ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE,
  LIST_FILTER_ALL, PORTFOLIO_USER_ROLES,
  PORTFOLIO_USER_ROLE_ADMIN, PORTFOLIO_USER_ROLE_OWNER,
  PORTFOLIO_USER_ROLE_STANDARD, PORTFOLIO_USER_ROLE_VIEWER,
} from 'src/util/constants';

import PortfolioUserMemberListRoleControl from './PortfolioUserMemberListRoleControl';
import PortfolioActiveStatusFilter from '../PortfolioActiveStatusFilter';
import PortfolioDateRangePicker from '../PortfolioDateRangePicker';

/**
 * @param {object} props
 * @param {object} props.filter - Filter object
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE | LIST_FILTER_ALL} props.filter.activeState
 * Whether the portfolio user is active or inactive.
 * @param {PORTFOLIO_USER_ROLE_OWNER |
 * PORTFOLIO_USER_ROLE_ADMIN | PORTFOLIO_USER_ROLE_STANDARD |
 * PORTFOLIO_USER_ROLE_VIEWER} props.filter.roleType - Selected user role or the default
 * All portfolio users.
 * @param {Function} props.filterUsersByRoleTypeFunc - Function to filter users by role type.
 * @param {object} props.portfolioActive - Portfolio's start and finish date object.
 * @param {Array<PORTFOLIO_USER_ROLES>} props.roles - Portfolio's user roles.
 * @param {Function} props.setFilterFunc - Function that sets the user's list table filters.
 * @param {object} props.timespan - The portfolio's timespan
 * @param {string} props.timezone - The portfolio's timezone
 * @param {Function} props.updateTimespanFunc - Function that update the date.
 * @returns {React.ReactComponentElement} PortfolioUserListControls component.
 */
function PortfolioUserMemberListControls(props) {
  const {
    filter,
    filterUsersByRoleTypeFunc,
    portfolioActive,
    roles,
    setFilterFunc,
    timespan,
    timezone,
    updateTimespanFunc,
  } = props;
  const { activeState, roleType } = filter;
  return (
    <ButtonToolbar>
      <PortfolioActiveStatusFilter
        activeState={activeState}
        setFilterFunc={setFilterFunc}
        roleType={roleType}
      />
      <PortfolioUserMemberListRoleControl
        filterUsersByRoleTypeFunc={filterUsersByRoleTypeFunc}
        roles={roles}
        roleType={roleType}
      />
      <PortfolioDateRangePicker
        active={portfolioActive}
        timespan={timespan}
        timezone={timezone}
        updateTimespanFunc={updateTimespanFunc}
      />
    </ButtonToolbar>
  );
}

export default PortfolioUserMemberListControls;

PortfolioUserMemberListControls.propTypes = {
  filter: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  filterUsersByRoleTypeFunc: PropTypes.func.isRequired,
  portfolioActive: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilterFunc: PropTypes.func.isRequired,
  timespan: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  timezone: PropTypes.string.isRequired,
  updateTimespanFunc: PropTypes.func.isRequired,
};
