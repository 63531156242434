import { DateTime, Duration } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import subHeading from 'src/components/Portfolio/helpers/common';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import FlashesStore from 'src/stores/FlashesStore';
import {
  ACTIVE_STATE_ACTIVE, ALL_PORTFOLIO_USERS,
} from 'src/util/constants';
import BrowserProtocol from 'src/util/history';
import removeDuplicates from 'src/util/removeDuplicates';
import { getDashboardTimeScope, getTimezone } from 'src/util/time';

import PortfolioUserMemberListControls from './PortfolioUserMemberListControls';
import PorfolioUserMemberListTable from './PortfolioUserMemberListTable';

const PortfolioUserMemberListFragment = graphql`
fragment PortfolioUserMemberList_portfolio on Portfolio
@refetchable(queryName: "PortfolioUserMemberListPaginationQuery")
@argumentDefinitions(
  cursor: { type: "String" }
  count: { type: "Int"}
  start: { type: "Timestamp!" }
  finish: { type: "Timestamp!" }
)
{
    uuid
    externalIdentifier
    active { start finish }
    title
    userMembers(first: $count, after: $cursor, start: $start, finish: $finish)
    @connection(key: "PortfolioUserMembersList_userMembers") {
      edges {
        cursor
        node {
          uuid
          externalIdentifier
          active {
            start
            finish
          }
          user {
            uuid
            externalIdentifier
            givenName
            familyName
            email
          }
          role
        }
      }
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
    propertyMembers(first: $count) {
      count
      edges {
        node {
          uuid
        }
      }
    }
  }
`;

/**
 *
 * @param {object} props
 * @param {object} props.portfolio
 * @param {object} props.match
 * @returns {React.ReactComponentElement} users list table component.
 */
function PortfolioUserMemberList(props) {
  if (!props) {
    return <Loading />;
  }
  const { portfolio, match } = props || {};

  const { params, location } = match || {};
  const { search } = location || {};
  const searchParams = new URLSearchParams(search);

  const start = searchParams.get('start');
  const finish = searchParams.get('finish');
  const { uuid } = params || {};

  if (!uuid) {
    return <Loading />;
  }

  const {
    data, loadNext, hasNext, isLoadingNext, refetch,
  } = usePaginationFragment(PortfolioUserMemberListFragment, portfolio);

  const timezone = getTimezone();

  const {
    active: portfolioActive, title, propertyMembers, userMembers,
  } = data || {};

  const portfolioTitle = title;

  const portfolioUsers = userMembers?.edges.map((edge) => {
    const { active, role, user } = edge.node;
    return { active, role, ...user };
  });

  const firstTimeRender = useRef(true);

  const { edges: properties } = propertyMembers || {};
  const { edges: users } = userMembers || {};
  const propertyCount = properties?.length;
  const userCount = users?.length;

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_user_member_list.page.title', defaultMessage: 'Enosi - Portfolio: {portfolioTitle}' }, { portfolioTitle });
  const breadcrumbTitle = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_user_member_list.breadcrumbs.portfolio.label', defaultMessage: 'Portfolios' });
  const usersTitle = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_user_member_list.heading.users.title', defaultMessage: 'Users' });

  const [filter, setFilter] = useState({
    roleType: ALL_PORTFOLIO_USERS,
    activeState: ACTIVE_STATE_ACTIVE,
  });
  const updatedFinish = DateTime.fromISO(finish, { zone: timezone }).plus(Duration.fromISO('P1D')).startOf('day').toSeconds();

  const { s, f } = getDashboardTimeScope(
    DateTime.fromISO(start, { zone: timezone }).toSeconds(),
    updatedFinish,
  );

  const [startTime, setStartTime] = useState(s);
  const [finishTime, setFinishTime] = useState(f);

  const updateTimespan = (selectedTimespan) => {
    if (!selectedTimespan) return;
    const { start: selectedStart, finish: selectedFinish } = selectedTimespan;
    setStartTime(selectedStart);
    setFinishTime(selectedFinish);
  };

  const filteredPortfolioUsers = portfolioUsers?.filter(
    (portfolioUser) => filter.roleType === portfolioUser.role
      || filter.roleType === ALL_PORTFOLIO_USERS,
  );

  const roles = [];
  let uniqueRoleList = [];
  if (portfolioUsers) {
    Object.values(portfolioUsers).forEach(({ role }) => {
      if (!role) return;
      if (role) {
        roles.push(role);
      }
    });
    uniqueRoleList = removeDuplicates(roles);
  }

  const doRefetch = () => {
    refetch(
      {
        start: startTime?.toSeconds(),
        finish: finishTime?.toSeconds() || startTime?.plus(Duration.fromISO('P1D')).startOf('day').toSeconds(),
      },
      {
        onComplete: (error) => {
          if (error) {
            FlashesStore.flash(FlashesStore.ERROR, error);
          } else {
            const { pathname } = location;
            const dateRangeParams = {
              start: startTime?.toISODate(),
              finish: finishTime?.toISODate() || startTime?.toISODate(),
            };

            BrowserProtocol.navigate({
              action: 'REPLACE',
              pathname,
              search: `?${Object.keys(dateRangeParams).map((k) => [k, encodeURIComponent(dateRangeParams[k])].join('=')).join('&')}`,
              hash: '',
              state: dateRangeParams,
            });
          }
        },
      },
    );
  };

  useEffect(() => {
    if (!firstTimeRender.current) {
      doRefetch();
    }
  }, [startTime, finishTime]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  /**
   * Loads the next set of users when the user hits the load more button.
   * @returns {void}
   */
  const loadMore = () => {
    if (!hasNext || isLoadingNext) {
      return;
    }

    loadNext(
      50,
      { onComplete: (error) => { console.log({ error }); } },
    );
  };

  const finalTimespan = (startTime && finishTime) ? { finish: DateTime.fromISO(finishTime, { zone: timezone }), start: DateTime.fromISO(startTime, { zone: timezone }) } : '';
  const headerInputs = {
    uuid,
    propertyCount,
    timespan: finalTimespan,
    userCount,
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumbs breadcrumbs={[{ name: breadcrumbTitle, path: '/portfolios' }, { name: portfolioTitle, path: `/portfolios/${uuid}` }, { name: usersTitle }]} />
      <Heading
        title={portfolioTitle}
        subtitle={subHeading(headerInputs)}
        context={usersTitle}
      />
      <PortfolioUserMemberListControls
        filter={filter}
        filterUsersByRoleTypeFunc={(role) => setFilter(
          { activeState: filter.activeState, roleType: role },
        )}
        portfolioActive={portfolioActive}
        roles={uniqueRoleList}
        setFilterFunc={setFilter}
        timespan={{ start: startTime, finish: finishTime }}
        timezone={timezone}
        updateTimespanFunc={(selectedTimerange) => updateTimespan(selectedTimerange)}
      />
      <PorfolioUserMemberListTable
        activeState={filter.activeState}
        hasNext={hasNext}
        loadMore={loadMore}
        rowsData={filteredPortfolioUsers}
      />
    </>
  );
}

export default PortfolioUserMemberList;

PortfolioUserMemberList.propTypes = {
  // note: known bug https://github.com/jsx-eslint/eslint-plugin-react/issues/1002#issuecomment-320160686
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  portfolio: PropTypes.object.isRequired,
  match: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      state: PropTypes.object,
    }),
  }).isRequired,
};
