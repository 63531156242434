import { Link } from 'found';
import { DateTime, Duration } from 'luxon';
import PropTypes from 'prop-types';
import React, {
  useEffect, useRef, useState, useTransition,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useRefetchableFragment } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import LoadingOverlay from 'src/components/Dashboard/LoadingOverlay';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import FlashesStore from 'src/stores/FlashesStore';
import {
  LIST_FILTER_ALL, TRADE_DIRECTION_BUY,
  TRADE_DIRECTION_SELL, TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED,
  TRADE_TYPE_RESIDUAL,
} from 'src/util/constants';
import BrowserProtocol from 'src/util/history';
import { dateToTimeInTimezone, getTimezone } from 'src/util/time';

import TradeListControls from './TradeListControls';
import TradeListTable from './TradeListTable';

const TradeListFragment = graphql`
fragment TradeList on Query
@refetchable(queryName: "TradeListRefetchQuery")
  @argumentDefinitions(
    uuid: {type: "ID!"},
    start: { type: "Timestamp!" }
    finish: { type: "Timestamp!" }
  ) {
  property(uuid:$uuid) {
    uuid
    title
    address {
      line1
      line2
      city
      state
      postcode
      gps {
        latitude
        longitude
      }
    }
    ...TradeListTable_property @arguments(start: $start, finish: $finish)
  }
}
`;

/**
 * Returns the property settings controller
 * @param {object} property
 * @returns {React.ReactElement} - property setting controller.
 */
const renderPropertyControls = (property) => (
  <ButtonToolbar>
    <ButtonGroup className="ms-2">
      <Link
        to={`/properties/${property.uuid}/settings`}
        className="btn btn-darken"
      >
        <FormattedMessage id="trade.trade_list.property_settings.label" defaultMessage="Property Settings" />
      </Link>
    </ButtonGroup>
  </ButtonToolbar>
);

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - TradeList component
 */
function TradeList(props) {
  if (!props) {
    return <Loading />;
  }
  const intl = useIntl();
  // Set times and more
  const [data, refetch] = useRefetchableFragment(TradeListFragment, props);
  const { property } = data || {};
  const { __fragmentOwner } = property;
  const { variables } = __fragmentOwner;
  const { start } = variables;

  const timezone = getTimezone(property);

  let initialDate = DateTime.local({ zone: timezone }).startOf('day').minus(Duration.fromISO('P1D'));

  if (start && start !== undefined) {
    const ts = DateTime.fromSeconds(start, { zone: timezone });
    if (ts.invalidExplanation === null) {
      initialDate = ts;
    }
  }

  const defaultFilter = {
    direction: [],
    type: [],
  };
  const [isPending, startTransition] = useTransition();
  const [date, setDate] = useState(initialDate);
  const [filter, setFilter] = useState(defaultFilter);
  const firstTimeRender = useRef(true);

  const doRefetch = () => {
    const dateInTimezone = dateToTimeInTimezone(date, timezone);
    startTransition(() => {
      refetch(
        {
          start: dateInTimezone.toSeconds(),
          finish: dateInTimezone.plus(Duration.fromISO('P1D')).startOf('day').toSeconds(),
        },
        {
          onComplete: (error) => {
            if (error) {
              FlashesStore.flash(FlashesStore.ERROR, error);
            } else {
              const { match } = props;
              const { location } = match;
              const { pathname } = location;

              BrowserProtocol.navigate({
                action: 'REPLACE',
                pathname,
                search: `?date=${dateInTimezone.toISODate()}`,
                hash: '',
                state: { date: dateInTimezone.toISODate() },
              });
            }
          },
        },
      );
    });
  };

  useEffect(() => {
    if (!firstTimeRender.current) {
      doRefetch();
    }
  }, [date]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  const filterFunc = (key, value) => {
    const { direction, type } = filter;

    const newFilter = { direction, type };

    if (value === LIST_FILTER_ALL) {
      newFilter.direction = [];
      newFilter.type = [];
    } else if (newFilter[key].includes(value)) {
      newFilter[key].splice(newFilter[key].indexOf(value), 1);
    } else {
      newFilter[key].push(value);
    }

    const allDirections = [TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL];
    const allTypes = [TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL];

    if (allDirections.every((k) => direction.includes(k))) {
      newFilter.direction = [];
    }
    if (allTypes.every((k) => type.includes(k))) {
      newFilter.type = [];
    }
    setFilter(newFilter);
  };

  const { address, uuid, title } = property;
  const addr = new Address(address);
  const pageTitle = intl.formatMessage({ id: 'trade.trade_list.page.title', defaultMessage: 'Enosi - Property: {title}' }, { title });
  const applyingNewTimeRangeLabel = intl.formatMessage({ id: 'trade.trade_list.applying_new_time_range.overlay.label', defaultMessage: 'Applying new time range...' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      {isPending && <LoadingOverlay label={applyingNewTimeRangeLabel} />}
      <Breadcrumbs
        breadcrumbs={[
          {
            name: <FormattedMessage id="trade.trade_list.properties.breadcrumb" defaultMessage=" Properties" />, path: '/properties',
          },
          { name: title, path: `/properties/${uuid}` },
          { name: <FormattedMessage id="trade.trade_list.trades.breadcrumb" defaultMessage="Trades" /> },
        ]}
      />
      <Heading
        title={title}
        subtitle={addr.simpleString()}
        context={<FormattedMessage id="trade.trade_list.heading.title" defaultMessage="Trades" />}
        controls={renderPropertyControls(property)}
      />
      <TradeListControls
        date={date}
        dateUpdateFunc={(selectedDate) => setDate(selectedDate)}
        filter={filter}
        filterFunc={(key, value) => filterFunc(key, value)}
      />
      <TradeListTable
        date={date}
        dateUpdateFunc={(selectedDate) => setDate(selectedDate)}
        property={property}
        filter={filter}
        timezone={timezone}
      />
    </>
  );
}

export default TradeList;

TradeList.propTypes = {
  match: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }),
};

TradeList.defaultProps = {
  relay: undefined,
};
