import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createPaginationContainer, graphql } from 'react-relay';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import FlashesStore from 'src/stores/FlashesStore';
import {
  LIST_FILTER_ALL, LOAD_MORE, REFETCH,
} from 'src/util/constants';
import BrowserProtocol from 'src/util/history';
import { dateToTimeInTimezone, getTimezone } from 'src/util/time';

import AdminMeterTradeRulesControls from './AdminMeterTradeRulesControls';
import AdminMeterTradeRulesTable from './AdminMeterTradeRulesTable';

class AdminMeterTradeRules extends React.Component {
  constructor(props) {
    super(props);

    this.dateUpdateFunc = this.dateUpdateFunc.bind(this);
    this.filterFunc = this.filterFunc.bind(this);
    this.loadMore = this.loadMore.bind(this);

    const { variables, meter } = this.props;
    const { property } = meter;
    const { state, type, start } = variables;

    const timezone = getTimezone(property);

    this.state = {
      date: start ? DateTime.fromSeconds(start, { zone: timezone }) : null,
      filter: {
        state: state || null,
        type: type || null,
      },
      updating: null,
    };
  }

  doRefetch = () => {
    const { relay, meter, match } = this.props;
    const { property } = meter;

    const { uuid } = meter;
    const { location } = match;
    const { pathname } = location;

    const { date, filter } = this.state;
    const { state, type } = filter;

    const timezone = getTimezone(property);

    const variables = { uuid, state, type };
    if (date) {
      const timeInTimezone = dateToTimeInTimezone(date, timezone);
      variables.start = timeInTimezone.startOf('day').toSeconds();
      variables.finish = timeInTimezone.startOf('day').plus({ days: 1 }).toSeconds();
    }

    const queryParameters = new URLSearchParams();
    if (state) {
      queryParameters.append('state', state);
    }
    if (type) {
      queryParameters.append('type', type);
    }
    if (date) {
      queryParameters.append('date', date.toISODate({ zone: timezone }));
    }

    this.setState({ updating: REFETCH });
    relay.refetchConnection(
      50,
      (error) => {
        if (error) {
          FlashesStore.flash(FlashesStore.ERROR, error);
        } else {
          BrowserProtocol.navigate({
            action: 'REPLACE',
            pathname,
            search: queryParameters.toString() ? `?${queryParameters.toString()}` : '',
            hash: '',
          });
        }
        this.setState({ updating: null });
      },
      variables,
    );
  };

  dateUpdateFunc = (date) => {
    const { updating } = this.state;

    if (updating) {
      return;
    }

    this.setState({ date }, this.doRefetch);
  };

  filterFunc = (key, value) => {
    const { filter, updating } = this.state;
    const { state, type } = filter;

    if (updating) {
      return;
    }

    const newFilter = { state, type };
    const newState = { filter: newFilter };

    if (value === LIST_FILTER_ALL) {
      newFilter.state = null;
      newFilter.type = null;
      newState.date = null;
    } else if (filter[key] === value) {
      newFilter[key] = null;
    } else {
      newFilter[key] = value;
    }

    this.setState(newState, this.doRefetch);
  };

  loadMore = () => {
    const { relay } = this.props;
    const { updating } = this.state;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    if (updating) {
      return;
    }

    this.setState({ updating: LOAD_MORE });
    relay.loadMore(
      50,
      (error) => {
        console.log(error);
        this.setState({ updating: null });
      },
    );
  };

  render() {
    if (!this.props) {
      return <Loading />;
    }

    const { relay, meter } = this.props;
    const {
      uuid, identifier, rules,
    } = meter;

    const { date, filter, updating } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Enosi Admin - Meter - ${identifier} - Trade Rules`}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Admin', path: '/admin' },
            { name: 'Meters', path: '/admin/meters' },
            { name: `Meter: ${identifier}`, path: `/admin/meters/${uuid}` },
            { name: 'Trade Rules' },
          ]}
        />

        <Heading
          title={identifier}
          context="Trade Rules"
        />

        <AdminMeterTradeRulesControls
          date={date}
          dateUpdateFunc={this.dateUpdateFunc}
          filter={filter}
          filterFunc={this.filterFunc}
        />

        <AdminMeterTradeRulesTable
          meter={meter}
          rules={rules}
          moreFunc={this.loadMore}
          hasMore={relay.hasMore()}
          updating={updating}
        />
      </>
    );
  }
}

AdminMeterTradeRules.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
    refetchConnection: PropTypes.func,
  }).isRequired,
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  variables: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

AdminMeterTradeRules.defaultProps = {
  meter: null,
};

export default createPaginationContainer(
  AdminMeterTradeRules,
  {
    meter: graphql`
      fragment AdminMeterTradeRules_meter on Meter {
        uuid
        identifier
        title
        tradePointId
        property {
          uuid
          timezone
        }
        rules(
          first: $count
          after: $cursor
          state: $state
          type: $type
          start: $start
          finish: $finish
        ) @connection(key: "AdminMeterTradeRules_rules") {
          edges {
            cursor
            node {
              uuid
            }
          }
          ...AdminMeterTradeRulesTable_rules
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.meter.rules;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const variables = {
        count, cursor, state: null, type: null, start: null, finish: null,
      };

      // Set if present
      const {
        uuid, state, type, start, finish,
      } = fragmentVariables;
      if (uuid !== undefined && uuid !== null) { variables.uuid = uuid; }
      if (state !== undefined && state !== null) { variables.state = state; }
      if (type !== undefined && type !== null) { variables.type = type; }
      if (start !== undefined && start !== null) { variables.start = start; }
      if (finish !== undefined && finish !== null) { variables.finish = finish; }

      return variables;
    },
    query: graphql`
      # Pagination query to be fetched upon calling 'loadMore'.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query AdminMeterTradeRules_Pagination_Query(
        $uuid: ID!
        $count: Int!
        $cursor: String
        $state: TradeRuleState
        $type: TradeType
        $start: Timestamp
        $finish: Timestamp
      ) {
        meter(uuid: $uuid) {
          ...AdminMeterTradeRules_meter
        }
      }
    `,
  },
);
