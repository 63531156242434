import { DateTime, Duration } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import getElementsByState from 'src/components/Portfolio/PortfolioShow/helpers';
import subHeading from 'src/components/Portfolio/helpers/common';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import FlashesStore from 'src/stores/FlashesStore';
import { ACTIVE_STATE_ACTIVE } from 'src/util/constants';
import BrowserProtocol from 'src/util/history';
import { getDashboardTimeScope, getTimezone } from 'src/util/time';

import PortfolioPropertyMemberListControls from './PortfolioPropertyMemberListControls';
import PortfolioPropertyMemberListTable from './PortfolioPropertyMemberListTable';

const PortfolioPropertyMemberListFragment = graphql`
fragment PortfolioPropertyMemberList_portfolio on Portfolio
@refetchable(queryName: "PortfolioPropertyMemberListPaginationQuery")
@argumentDefinitions(
  cursor: { type: "String" },
  count: { type: "Int"}
  start: { type: "Timestamp!" }
  finish: { type: "Timestamp!" }
) {
    uuid
    externalIdentifier
    active { start finish }
    title
    propertyMembers(first: $count, after: $cursor, start: $start, finish: $finish)
    @connection(key: "PortfolioPropertyMemberList_propertyMembers"){
      edges {
        node {
          uuid
          externalIdentifier
          active { start finish }
          property {
            uuid
            title
            address {
              line1
              line2
              city
              state
              postcode
              country
            }
          }
        }
      }
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
    userMembers(first: $count) {
      count
      edges {
        node {
          uuid
        }
      }
    }
  }
`;

/**
 * Description
 * @param {object} props
 * @param {object} props.portfolio
 * @param {object} props.match
 * @returns {React.ReactElement} - PortfolioPropertyMemberList component
 */
function PortfolioPropertyMemberList(props) {
  if (!props) {
    return <Loading />;
  }

  const { portfolio, match } = props || {};

  const { params, location } = match || {};
  const { search } = location || {};
  const searchParams = new URLSearchParams(search);

  const start = searchParams.get('start');
  const finish = searchParams.get('finish');
  const { uuid } = params || {};

  if (!uuid) {
    return <Loading />;
  }

  const {
    data, loadNext, hasNext, isLoadingNext, refetch,
  } = usePaginationFragment(PortfolioPropertyMemberListFragment, portfolio);
  const {
    active: portfolioActive, title, propertyMembers, userMembers,
  } = data;

  const timezone = getTimezone();

  const properties = propertyMembers?.edges?.map((edge) => {
    const { active, property } = edge.node;
    return { active, ...property };
  });
  const { edges: users } = userMembers || {};
  const firstTimeRender = useRef(true);

  const [activeState, setActiveState] = useState(ACTIVE_STATE_ACTIVE);
  const updatedFinish = DateTime.fromISO(finish).plus(Duration.fromISO('P1D')).startOf('day').toSeconds();
  const { s, f } = getDashboardTimeScope(
    DateTime.fromISO(start).toSeconds(),
    updatedFinish,
  );

  const [startTime, setStartTime] = useState(s);
  const [finishTime, setFinishTime] = useState(f);

  const updateTimespan = (selectedTimespan) => {
    if (!selectedTimespan) return;
    const { start: selectedStart, finish: selectedFinish } = selectedTimespan;
    setStartTime(selectedStart);
    setFinishTime(selectedFinish);
  };

  const doRefetch = () => {
    refetch(
      {
        start: startTime?.toSeconds(),
        finish: finishTime?.toSeconds() || startTime?.plus(Duration.fromISO('P1D')).startOf('day').toSeconds(),
      },
      {
        onComplete: (error) => {
          if (error) {
            FlashesStore.flash(FlashesStore.ERROR, error);
          } else {
            const { pathname } = location;

            const dateRange = {
              start: startTime?.toISODate(),
              finish: finishTime?.toISODate() || startTime?.toISODate(),
            };

            BrowserProtocol.navigate({
              action: 'REPLACE',
              pathname,
              search: `?${Object.keys(dateRange).map((k) => [k, encodeURIComponent(dateRange[k])].join('=')).join('&')}`,
              hash: '',
              state: dateRange,
            });
          }
        },
      },
    );
  };

  useEffect(() => {
    if (!firstTimeRender.current) {
      doRefetch();
    }
  }, [startTime, finishTime]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  /**
   * Loads the next set of users when the user hits the load more button.
   * @returns {void}
   */
  const loadMore = () => {
    if (!hasNext || isLoadingNext) {
      return;
    }

    loadNext(
      50,
      { onComplete: (error) => { console.log({ error }); } },
    );
  };

  const setFilter = (inputs) => {
    if (!inputs) {
      return;
    }
    setActiveState(inputs.activeState);
  };

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_property_member_list.page.title', defaultMessage: 'Enosi - Portfolio: {portfolioTitle}' }, { portfolioTitle: title });
  const breadcrumbPortfolio = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_property_member_list.portfolios.breadcrumb', defaultMessage: 'Portfolios' });
  const propertiesLabel = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_property_member_list.properties.label', defaultMessage: 'Properties' });
  const propertiesList = properties ? getElementsByState(properties, activeState) : [];
  const finalTimespan = (startTime && finishTime) ? { finish: DateTime.fromISO(finishTime, { zone: timezone }), start: DateTime.fromISO(startTime, { zone: timezone }) } : '';
  const headerInputs = {
    uuid,
    propertyCount: properties?.length,
    timespan: finalTimespan,
    userCount: users?.length,
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumbs
        breadcrumbs={[
          {
            name: breadcrumbPortfolio, path: '/portfolios',
          },
          { name: title, path: `/portfolios/${uuid}` },
          { name: propertiesLabel },
        ]}
      />
      <Heading
        alias={title}
        subtitle={subHeading(headerInputs)}
        context={propertiesLabel}
      />
      <PortfolioPropertyMemberListControls
        active={portfolioActive}
        activeState={activeState}
        setFilterFunc={setFilter}
        timespan={{ start: startTime, finish: finishTime }}
        timezone={timezone}
        updateTimespanFunc={(selectedTimerange) => updateTimespan(selectedTimerange)}
      />
      <PortfolioPropertyMemberListTable
        hasNext={hasNext}
        loadMore={loadMore}
        data={propertiesList}
        state={activeState}
      />
    </>
  );
}
export default PortfolioPropertyMemberList;

PortfolioPropertyMemberList.propTypes = {
  // note: known bug https://github.com/jsx-eslint/eslint-plugin-react/issues/1002#issuecomment-320160686
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  portfolio: PropTypes.object.isRequired,
  match: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      state: PropTypes.object,
    }),
  }).isRequired,
};
