import PropTypes from 'prop-types';
import React from 'react';
import { ButtonToolbar } from 'reactstrap';

import PortfolioActiveStatusFilter from '../PortfolioActiveStatusFilter';
import PortfolioDateRangePicker from '../PortfolioDateRangePicker';

/**
 * @param {object} props
 * @param {object} props.active - portfolio's active time range.
 * @param {string} props.activeState - Whether the portfolio user is active or inactive.
 * @param {Function} props.setFilterFunc - Function that sets the table filters.
 * @param {object} props.timespan - selected timespan
 * or today's date (start and end of day) (default).
 * @param {string} props.timezone
 * @param {Function} props.updateTimespanFunc - Function that update the date.
 * @returns {React.ReactComponentElement} PortfolioPropertiesControls component.
 */
function PortfolioPropertyMemberListControls(props) {
  const {
    active, activeState, setFilterFunc, timespan, timezone,
    updateTimespanFunc,
  } = props;

  return (
    <ButtonToolbar>
      <PortfolioActiveStatusFilter activeState={activeState} setFilterFunc={setFilterFunc} />
      <PortfolioDateRangePicker
        active={active}
        timespan={timespan}
        timezone={timezone}
        updateTimespanFunc={updateTimespanFunc}
      />
    </ButtonToolbar>
  );
}

PortfolioPropertyMemberListControls.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  active: PropTypes.object.isRequired,
  activeState: PropTypes.string.isRequired,
  setFilterFunc: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  timespan: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  updateTimespanFunc: PropTypes.func.isRequired,
};
export default PortfolioPropertyMemberListControls;
