import { DateTime, Duration } from 'luxon';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import getStartFormat from 'src/helpers/dateRangeHelpers';
import { getLocale } from 'src/util/i18n/handler';

const timeSpanButtonLabel = (s, f, timezone) => {
  const appLocale = getLocale();
  // If no finish and start ... erroneous ... return empty
  if (!s && !f) {
    return '';
  }
  if (s && !f) {
    return s.setLocale(appLocale).setZone(timezone).toFormat('d MMM yyyy');
  }

  if (!s && f) {
    return f.setLocale(appLocale).setZone(timezone).toFormat('d MMM yyyy');
  }

  // If it's for a single day, display a single day
  if (s.toSeconds() === f.toSeconds()) {
    return s.setLocale(appLocale).setZone(timezone).toFormat('DD');
  }

  // Otherwise let's make a nice label
  const startFormat = getStartFormat(s, f);

  const startLabel = s.setLocale(appLocale).setZone(timezone).toFormat(startFormat);
  const finishLabel = f.setLocale(appLocale).setZone(timezone).toFormat('DD');
  return [startLabel, finishLabel].flat().join(' - ');
};

/**
 * @param {object} props
 * @param {Function} props.onClick - Function to call when the button is clicked
 * @param {boolean} props.disabled - Whether the button is disabled
 * @param {string} props.value - date label to be displayed
 * @param {React.Ref} ref - React ref forwarded to the button
 * @returns {React.ReactComponentElement} -DateButton component
 */
const DateButton = forwardRef((props, ref) => {
  const {
    onClick,
    disabled,
    value,
  } = props;

  return (
    <Button size="sm" className="btn btn-darken" onClick={() => (onClick())} disabled={disabled} innerRef={ref}>
      {value}
    </Button>
  );
});

DateButton.displayName = 'DateButton';

DateButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
DateButton.defaultProps = {
  disabled: false,
  onClick: null,
  value: null,
};

/**
 * Wrap a DatePicker to support a custom timezone.
 * @param {object} props
 * @param {string} props.timezone
 * @param {DateTime} props.start
 * @param {DateTime} props.finish
 * @param {Function} props.ref
 * @param {Function} props.onChange
 * @param {Function} props.onCalendarClose
 * @param {Function} props.onCalendarOpen
 * @param {Function} props.customCalendarClose
 * @param {object} props.active
 * @returns {DatePickerInTimezone} -  React date picker component wrapped in a time zone(preferred)
 */
function DatePickerInTimezone({
  timezone, // Preferred timezone. May be different to local timezone.
  start, // System timezone.
  finish, // System timezone.
  ref,
  onChange,
  onCalendarClose,
  onCalendarOpen,
  customCalendarClose,
  active,
}) {
  // `start` and `finish` come in as DateTime objects in system timezone.
  // they are formatted to ISODate YYYY-MM-DD and then converted to JSDate
  // as the date picker expects a date in a JSDDate format.
  const startLocal = start ? DateTime.fromISO(start.toISODate()) : null;
  const finishLocal = finish ? DateTime.fromISO(finish.toISODate()) : null;
  const dpStart = startLocal?.toJSDate() || null;
  const dpFinish = finishLocal?.toJSDate() || null;

  // `activeStart` and `activeFinish` come in as seconds
  // they get parsed specifying the timezone and are formatted to ISODate
  // and then converted to JSDate as the date picker expects that format.
  const { start: activeStart, finish: activeFinish } = active || {};
  const activeStartLocal = activeStart
    ? DateTime.fromISO(DateTime.fromSeconds(activeStart, { zone: timezone }).toISODate()) : null;
  const activeFinishLocal = activeFinish
    ? DateTime.fromISO(DateTime.fromSeconds(activeFinish, { zone: timezone }).toISODate()) : null;
  const minDate = activeStartLocal?.toJSDate() || null;
  const maxDate = activeFinishLocal?.toJSDate() || null;

  // dates come in as JSDate in the system timezone and are converted to
  // DateTime objects in the specified timezone.
  // updatedStart and updatedFinish are then sent back to the parent component.
  // wich will trigger a refetch of the data.
  const wrappedOnChange = (dates) => {
    const [localStartJS, localFinishJS] = dates; // Local time.
    const localStart = localStartJS
      ? DateTime.fromJSDate(localStartJS) : null; // Local datetime.
    const localFinish = localFinishJS
      ? DateTime.fromJSDate(localFinishJS) : null; // Local datetime.

    const updatedStart = localStart
      ? DateTime.fromISO(localStart.toISODate(), { zone: timezone }) : null;
    const updatedFinish = localFinish
      ? DateTime.fromISO(localFinish.toISODate(), { zone: timezone }) : null;

    onChange([updatedStart, updatedFinish]);
  };

  return (
    <DatePicker
      locale={getLocale()}
      ref={(r) => { ref(r); }}
      value={timeSpanButtonLabel(start, finish, timezone)}
      selected={dpStart}
      startDate={dpStart || null}
      endDate={dpFinish || null}
      maxDate={maxDate}
      minDate={minDate}
      onChange={wrappedOnChange}
      onCalendarClose={onCalendarClose}
      onCalendarOpen={onCalendarOpen}
      customInput={<DateButton />}
      monthsShown={2}
      selectsRange
      shouldCloseOnSelect={false}
      popperPlacement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
    >
      <div
        style={{
          clear: 'both',
          textAlign: 'right',
          borderTop: '1px solid #ccc',
          padding: '1em',
        }}
      >
        <button className="btn btn-primary" type="button" onClick={customCalendarClose}>
          <FormattedMessage id="common.daterange_picker.form.submit" defaultMessage="Apply" />
        </button>
      </div>
    </DatePicker>
  );
}
/**
 * @param {object} props
 * @param {object} props.active - Active object
 * @param {number} props.active.start - Start
 * @param {number} props.active.finish - Finish
 * @param {object} props.timespan - Function to call when the date is changed
 * @param {object} props.timespan.start - Timespan start
 * @param {object} props.timespan.finish - Timespan finish
 * @param {string} props.timezone - Timespan finish
 * @param {Function} props.updateTimespanFunc - Function to call when the date is changed
 * @returns {React.ReactComponentElement} -PortfolioDatePicker component
 */
function PortfolioDateRangePicker(props) {
  const {
    active, timespan, timezone, updateTimespanFunc,
  } = props;

  const { start, finish } = timespan || {};
  const finalFinish = finish
    || start?.plus(Duration.fromISO('P1D')).toSeconds();

  const [startDate, setStartDate] = useState(start);
  const [finishDate, setFinishDate] = useState(finalFinish);
  const [myRef, setMyRef] = useState(null);

  const { start: portfolioStart, finish: portfolioFinish } = active || {};

  const onChangeFunc = (d) => {
    const [newStart, newFinish] = d;

    const noRanges = !portfolioStart && !portfolioFinish;
    const hasStartRange = portfolioStart && !portfolioFinish;
    const isRangeFilled = portfolioStart && portfolioFinish;

    if (noRanges || isRangeFilled) {
      setStartDate(newStart);
      setFinishDate(newFinish);
      return;
    }
    if (hasStartRange) {
      setStartDate(newStart);
      setFinishDate(newFinish);
    }
  };

  const onCloseFunc = () => {
    updateTimespanFunc({ start: startDate, finish: finishDate });
  };

  /**
   * Called when the date range picker's calendar is opened.
   * @returns {void}
   */
  const openCalendar = () => {
    const { start: prevStart, finish: prevFinish } = timespan;
    setStartDate(prevStart);
    setFinishDate(prevFinish);
  };

  /**
   * Called when the date range picker's calendar is closed.
   * @returns {void}
   */
  const closeCalendar = () => {
    myRef.setOpen(false);
  };

  const inputs = {
    timezone,
    start: startDate,
    finish: finishDate,
    ref: setMyRef,
    onChange: onChangeFunc,
    onCalendarClose: onCloseFunc,
    onCalendarOpen: openCalendar,
    customCalendarClose: closeCalendar,
    active,
  };
  return (
    <>
      {
        DatePickerInTimezone(inputs)
      }
    </>
  );
}

PortfolioDateRangePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  active: PropTypes.shape({
    start: PropTypes.number,
    finish: PropTypes.number,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  timespan: PropTypes.shape({
    start: PropTypes.instanceOf(DateTime),
    finish: PropTypes.instanceOf(DateTime),
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  updateTimespanFunc: PropTypes.func.isRequired,
};

DatePickerInTimezone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  active: PropTypes.object.isRequired,
  customCalendarClose: PropTypes.func.isRequired,
  finish: PropTypes.instanceOf(DateTime).isRequired,
  onCalendarClose: PropTypes.func.isRequired,
  onCalendarOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ref: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(DateTime).isRequired,
  timezone: PropTypes.string.isRequired,
};

export default PortfolioDateRangePicker;
