/* eslint-disable import/no-dynamic-require */
import {
  faDatabase, faDownload, faTableCells,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button, ButtonGroup, ButtonToolbar,
  Dropdown, DropdownItem, DropdownMenu,
  DropdownToggle, Label,
} from 'reactstrap';
import styled from 'styled-components';

import Chart from 'src/enosikit/components/Chart';
import getStartFormat from 'src/helpers/dateRangeHelpers';
import {
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PORTFOLIO, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE, DATA_PACK, DIRECTIONS,
  DOWNLOAD_SELECTOR, METER, SOURCE_HISTORIAN, SOURCE_TRADES,
  TRADE, UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY,
} from 'src/util/constants';
import { getLocale } from 'src/util/i18n/handler';
import { dateToTimeInTimezone } from 'src/util/time';

import { controlOptionFunc } from './helpers/common';

const BtnGroupWrapper = styled(ButtonGroup)`
  {
    z-index: 9999;
  }
`;

const DownloadWrapper = styled(DropdownToggle)`
{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
`;

/**
 * File download dropdown
 * @param {Function} handleDownload
 * @param {object} mainData
 * @param {boolean} dropdownOpen - flag to decide the status(close/open) of the dropdown
 * @param {Function} toggle
 * @param {object} dateRange - {start, finish}
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @returns {React.ReactElement} - file download dropdown.
 */
export const fileDownloadSelector = (
  handleDownload,
  mainData,
  dropdownOpen,
  toggle,
  dateRange,
  intl,
) => {
  const meterData = [];
  const tradeData = [];
  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) return;
    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const { meterDataAggregates, tradeSetSummaries } = mainData[dir].data[timestamp] || {};
      if (meterDataAggregates) {
        Object.keys(meterDataAggregates).forEach(
          (meterId) => meterData.push(meterDataAggregates[meterId]),
        );
      }
      if (tradeSetSummaries) {
        Object.keys(tradeSetSummaries).forEach(
          (tradeRuleId) => tradeData.push(tradeSetSummaries[tradeRuleId]),
        );
      }
    });
  });

  let meterDataCSVDisabled = false;
  let tradeDataCSVDisabled = false;
  let dataPackJSONDisabled = false;

  if (meterData.length === 0 && tradeData.length === 0) {
    meterDataCSVDisabled = true;
    tradeDataCSVDisabled = true;
    dataPackJSONDisabled = true;
  } else {
    meterDataCSVDisabled = meterData.length === 0;

    tradeDataCSVDisabled = tradeData.length === 0;
  }

  return (
    <Dropdown className="download-type-selector" isOpen={dropdownOpen} toggle={() => toggle(DOWNLOAD_SELECTOR)}>
      <DownloadWrapper className="btn btn-darken" caret><FontAwesomeIcon icon={faDownload} size="1x" className="me-2" /></DownloadWrapper>
      <DropdownMenu end>
        <DropdownItem
          disabled={meterDataCSVDisabled}
          className={`${meterDataCSVDisabled ? 'pe-none' : 'pe-auto'}`}
          onClick={() => handleDownload(METER, mainData, dateRange, intl)}
          // eslint-disable-next-line jsx-a11y/aria-proptypes
          aria-disabled={`${!!meterDataCSVDisabled}`}
          data-testid="meter-data-csv"
        >
          <Label>
            <FontAwesomeIcon icon={faTableCells} size="1x" className="me-2" />
            <FormattedMessage id="property.property_show.control.download.csv.meter_data" defaultMessage="Meter data (.csv)" />
          </Label>
        </DropdownItem>
        <DropdownItem
          disabled={tradeDataCSVDisabled}
          className={`${tradeDataCSVDisabled ? 'pe-none' : 'pe-auto'}`}
          onClick={() => handleDownload(TRADE, mainData, dateRange, intl)}
          // eslint-disable-next-line jsx-a11y/aria-proptypes
          aria-disabled={`${!!tradeDataCSVDisabled}`}
          data-testid="trade-data-csv"
        >
          <Label>
            <FontAwesomeIcon icon={faTableCells} size="1x" className="me-2" />
            <FormattedMessage id="property.property_show.control.download.csv.trade_data" defaultMessage="Trade data (.csv)" />
          </Label>
        </DropdownItem>
        <DropdownItem
          disabled={dataPackJSONDisabled}
          className={`${dataPackJSONDisabled ? 'pe-none' : 'pe-auto'}`}
          onClick={() => handleDownload(
            DATA_PACK,
            mainData,
            dateRange,
            intl,
          )}
          // eslint-disable-next-line jsx-a11y/aria-proptypes
          aria-disabled={`${!!dataPackJSONDisabled}`}
          data-testid="data-pack-json"
        >
          <Label>
            <FontAwesomeIcon icon={faDatabase} size="1x" className="me-2" />
            <FormattedMessage id="property.property_show.control.download.json" defaultMessage="Data pack (.json)" />
          </Label>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

/**
 * Wrap a DatePicker to support a custom timezone.
 * @param {object} props
 * @param {string} props.timezone
 * @param {DateTime} props.start
 * @param {DateTime} props.finish
 * @param {Function} props.ref
 * @param {Function} props.timeSpanButtonLabel
 * @param {number} props.maxNumberDays
 * @param {number} props.minStart
 * @param {Function} props.onChange
 * @param {Function} props.onCalendarClose
 * @param {Function} props.onCalendarOpen
 * @param {Function} props.customCalendarClose
 * @returns {DatePickerInTimezone} -  React date picker component wrapped in a time zone(preferred)
 */
function DatePickerInTimezone({
  timezone, // Preferred timezone for start/finish. May be different to local timezone.
  start, // Custom timezone.
  finish, // Custom timezone.
  ref,
  timeSpanButtonLabel,
  minStart, // Minumum start date the user can select from the date picker
  maxNumberDays, // Maximum number of days selected
  onChange,
  onCalendarClose,
  onCalendarOpen,
  customCalendarClose,
}) {
  const dpStart = start ? DateTime.local(start.year, start.month, start.day).toJSDate() : null;
  const dpFinish = finish ? DateTime.local(finish.year, finish.month, finish.day).toJSDate() : null;
  const dpMaxDate = dpStart && !dpFinish ? DateTime.fromJSDate(dpStart)
    .plus({ days: maxNumberDays }).toJSDate() : null;
  const dpMinDate = minStart ? DateTime.fromSeconds(minStart).toJSDate() : null;

  const wrappedOnChange = (dates) => {
    const [localStartJS, localFinishJS] = dates; // Local time.
    const localStart = localStartJS
      ? DateTime.fromJSDate(localStartJS) : null; // Local datetime.
    const localFinish = localFinishJS
      ? DateTime.fromJSDate(localFinishJS) : null; // Local datetime.

    const preferredStart = localStart ? dateToTimeInTimezone(localStart, timezone) : null;
    const preferredFinish = localFinish ? dateToTimeInTimezone(localFinish, timezone) : null;

    onChange([preferredStart, preferredFinish]);
  };

  return (
    <DatePicker
      locale={getLocale()}
      ref={(r) => { ref(r); }}
      value={timeSpanButtonLabel(start, finish)}
      selected={dpStart && !dpFinish ? dpStart : null}
      startDate={dpStart || null}
      endDate={dpFinish || null}
      maxDate={dpMaxDate}
      minDate={dpMinDate}
      onChange={wrappedOnChange}
      onCalendarClose={onCalendarClose}
      onCalendarOpen={onCalendarOpen}
      customInput={<TimeSpanButton />}
      monthsShown={2}
      selectsRange
      shouldCloseOnSelect={false}
      popperPlacement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
    >
      <div
        style={{
          clear: 'both',
          textAlign: 'right',
          borderTop: '1px solid #ccc',
          padding: '1em',
        }}
      >
        <button className="btn btn-primary" type="button" onClick={customCalendarClose}>
          <FormattedMessage id="common.daterange_picker.form.submit" defaultMessage="Apply" />
        </button>
      </div>
    </DatePicker>
  );
}

/**
 *
 * @param {object} root0
 * @param {Function} root0.controlSetStateFunc
 * @param {object} root0.chartView
 * @param {Function} root0.chartViewSelector
 * @param {Function} root0.downloadFunc
 * @param {boolean} root0.downloadSelectorOpen
 * @param {object} root0.mainData
 * @param {number} root0.minStart
 * @param {Function} root0.setChartViewFunc
 * @param {SOURCE_HISTORIAN |SOURCE_TRADES} root0.source
 * @param {object} root0.timespan
 * @param {Function} root0.timespanUpdateFunc
 * @param {Function} root0.toggleFunc
 * @param {UNIT_CARBON | UNIT_CURRENCY | UNIT_ENERGY} root0.unit
 * @param {any} root0.viewSelectorOpen
 * @param {string} root0.timezone
 * @returns {React.ReactComponentElement} - DashboardControl component
 */
function DashboardControl({
  controlSetStateFunc, chartView, chartViewSelector, downloadFunc,
  downloadSelectorOpen, mainData, minStart, setChartViewFunc, source, timespan, timespanUpdateFunc,
  timezone, toggleFunc, unit, viewSelectorOpen,
}) {
  if ((!mainData?.property && !mainData?.portfolio) || !timespan) {
    return (
      null
    );
  }

  const [start, setStart] = useState(timespan.start);
  const [finish, setFinish] = useState(timespan.finish);
  const [myRef, setMyRef] = useState(false);
  const [apply, setApply] = useState(false);

  useEffect(() => {
    if (timespan) {
      const { start: updatedStart, finish: updatedFinish } = timespan;
      setStart(updatedStart);
      setFinish(updatedFinish);
    }
  }, [timespan]);

  /**
   * Called when the date range picker's calendar is opened.
   * @returns {void}
   */
  const openCalendar = () => {
    const { start: prevStart, finish: prevFinish } = timespan;
    setStart(prevStart);
    setFinish(prevFinish);
    setApply(false);
  };

  /**
   * Called when the date range picker's calendar is closed.
   * @returns {void}
   */
  const closeCalendar = () => {
    setApply(true);
    myRef.setOpen(false);
  };

  /**
   * Label to use to indicate selected daterange in the HTML.
   * @param {DateTime} s start of daterange selected.
   * @param {DateTime} f finish of the daterange selected.
   * @returns {string} daterange label.
   */
  const timeSpanButtonLabel = (s, f) => {
    // If no finish ... erroneous ... return empty
    if (f === null) { return ''; }

    const appLocale = getLocale();

    // If it's for a single day, display a single day
    if (s.toSeconds() === f.toSeconds()) {
      return s.setLocale(appLocale).setZone(timezone).toFormat('DD');
    }

    // Otherwise let's make a nice label
    const startFormat = getStartFormat(s, f);

    const startDate = s.setLocale(appLocale).setZone(timezone).toFormat(startFormat);
    const finishDate = f.setLocale(appLocale).setZone(timezone).toFormat('DD');

    return [startDate, finishDate].flat().join(' - ');
  };

  const setTimeSpan = () => {
    if (!apply) { return; }

    const { start: prevStart, finish: prevFinish } = timespan;

    if (start === null) { setStart(prevStart.setZone(timezone)); }
    if (finish === null) { setFinish(start.setZone(timezone)); }

    // No change, nothing to see here...
    if (start.toSeconds() === prevStart.toSeconds()
      && (finish !== null && finish.toSeconds() === prevFinish.toSeconds())) {
      return;
    }

    timespanUpdateFunc(start, (finish === null ? start : finish));
  };

  /**
   * @param {Array<DateTime>} dates
   * @returns {void}
   */
  const updateStartAndFinish = (dates) => {
    const [newStart, newFinish] = dates;

    const noRanges = !start && !finish;
    const hasStartRange = start && !finish;
    const isRangeFilled = start && finish;

    if (noRanges || isRangeFilled) {
      setStart(newStart);
      setFinish(newFinish);
      return;
    }
    if (hasStartRange) {
      setStart(newStart);
      setFinish(newFinish);
    }
  };

  const renderTimeSpanPicker = (onChangeFunc, onCloseFunc) => (
    DatePickerInTimezone({
      timezone,
      start,
      finish,
      ref: setMyRef,
      timeSpanButtonLabel,
      maxNumberDays: 30,
      minStart,
      onChange: onChangeFunc,
      onCalendarClose: onCloseFunc,
      onCalendarOpen: openCalendar,
      customCalendarClose: closeCalendar,
    })
  );
  if ((!mainData?.property && !mainData?.portfolio) || !timespan) {
    return (
      null
    );
  }

  const intl = useIntl();
  const isPortfolio = !downloadFunc;

  return (
    <ButtonToolbar>

      <BtnGroupWrapper className="ms-2 mb-2">
        {renderTimeSpanPicker(updateStartAndFinish, setTimeSpan)}
      </BtnGroupWrapper>

      <Chart.ChartControls
        buttons={controlOptionFunc(unit, source, isPortfolio)}
        onButtonClick={(opts) => { controlSetStateFunc(opts); }}
      />
      <ButtonGroup>
        {chartViewSelector(
          viewSelectorOpen,
          toggleFunc,
          chartView,
          setChartViewFunc,
          source,
        )}
        {
          downloadFunc && fileDownloadSelector(
            downloadFunc,
            mainData,
            downloadSelectorOpen,
            toggleFunc,
            { start, finish },
            intl,
          )
        }
      </ButtonGroup>

    </ButtonToolbar>
  );
}

DashboardControl.propTypes = {
  controlSetStateFunc: PropTypes.func.isRequired,
  chartView: PropTypes.shape({
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_PORTFOLIO,
      DATA_AGGREGATE_BY_PROPERTY,
      DATA_AGGREGATE_BY_METER]).isRequired,
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE]).isRequired,
  }).isRequired,
  chartViewSelector: PropTypes.func.isRequired,
  downloadSelectorOpen: PropTypes.bool.isRequired,
  downloadFunc: PropTypes.func,
  mainData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  minStart: PropTypes.number,
  setChartViewFunc: PropTypes.func.isRequired,
  source: PropTypes.oneOf([SOURCE_HISTORIAN, SOURCE_TRADES]).isRequired,
  unit: PropTypes.oneOf([UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY]).isRequired,
  timespan: PropTypes.shape({
    start: PropTypes.instanceOf(DateTime),
    finish: PropTypes.instanceOf(DateTime),
  }).isRequired,
  timespanUpdateFunc: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  toggleFunc: PropTypes.func.isRequired,
  viewSelectorOpen: PropTypes.bool.isRequired,
};
DashboardControl.defaultProps = {
  downloadFunc: null,
  minStart: null,
};
DatePickerInTimezone.propTypes = {
  customCalendarClose: PropTypes.func.isRequired,
  finish: PropTypes.instanceOf(DateTime).isRequired,
  maxNumberDays: PropTypes.number.isRequired,
  minStart: PropTypes.number,
  onCalendarClose: PropTypes.func.isRequired,
  onCalendarOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ref: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(DateTime).isRequired,
  timeSpanButtonLabel: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};
DatePickerInTimezone.defaultProps = {
  minStart: null,
};

// Ref: <https://github.com/Hacker0x01/react-datepicker/issues/862>
//
// eslint-disable-next-line react/prefer-stateless-function
class TimeSpanButton extends React.Component {
  // TODO: smatter output formatting of the range. For example:
  // - Same month: "20 - 25 Jun 2020",
  // - Same year: "20 Jun - 4 Jul 2020",
  // - Otherwise "19 Dec 2019 - 18 Jan 2020")  render() {
  render() {
    const {
      disabled,
      onClick,
      value,
    } = this.props;

    return (
      <Button className="btn btn-darken" onClick={() => (onClick())} disabled={disabled}>
        {value}
      </Button>
    );
  }
}

TimeSpanButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
TimeSpanButton.defaultProps = {
  disabled: false,
  onClick: null,
  value: 'loading...',
};

export default DashboardControl;

export { TimeSpanButton };
