import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Loading from 'src/components/Loading';
import {
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
  SOURCE_HISTORIAN, SOURCE_TRADES,
} from 'src/util/constants';

import PropertyShowChart from './PropertyShowChart';
import PropertyShowTradeSummary from './PropertyShowTradeSummary';

class PropertyShowTradeEnergy extends React.Component {
  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      chartView, controlSetStateFunc, handleTimespanUpdate,
      historianAggregation, mainData, source, timespan,
      tradeAggregation, timezone, unit,
    } = this.props;

    return (
      <>
        <PropertyShowChart
          mainData={mainData}
          historianAggregation={historianAggregation}
          timespan={timespan}
          timezone={timezone}
          tradeAggregation={tradeAggregation}
          handleTimespanUpdate={handleTimespanUpdate}
          unit={unit}
          source={source}
          controlSetStateFunc={controlSetStateFunc}
          chartView={chartView}
        />
        <PropertyShowTradeSummary
          mainData={mainData}
        />
      </>
    );
  }
}

PropertyShowTradeEnergy.propTypes = {
  controlSetStateFunc: PropTypes.func.isRequired,
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_PROPERTY,
      DATA_AGGREGATE_BY_METER]).isRequired,
  }).isRequired,
  handleTimespanUpdate: PropTypes.func.isRequired,
  historianAggregation: PropTypes.string.isRequired,
  mainData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  source: PropTypes.oneOf([SOURCE_HISTORIAN, SOURCE_TRADES]).isRequired,
  timespan: PropTypes.shape({
    start: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    finish: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  tradeAggregation: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};

export default PropertyShowTradeEnergy;
