import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Dropdown, DropdownItem, DropdownMenu,
  Input, Label,
} from 'reactstrap';

import { ViewWrapper } from 'src/components/Dashboard/DashboardControl/helpers/common';
import {
  DATA_AGGREGATE_BY_PORTFOLIO, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
  SOURCE_HISTORIAN, SOURCE_TRADES, VIEW_SELECTOR,
  X_AXIS_DOMAIN_DYNAMIC, X_AXIS_DOMAIN_FIXED,
} from 'src/util/constants';

/**
 * Chart view selector dropdown
 * @param {boolean} dropdownOpen - flag to decide the status(close/open) of the dropdown
 * @param {Function} toggle - function to toggle the dropdown
 * @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PORTFOLIO | DATA_AGGREGATE_BY_PROPERTY} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @param {X_AXIS_DOMAIN_FIXED | X_AXIS_DOMAIN_DYNAMIC} chartView.xAxisDomain
 * @param {Function} setChartView - function that sets the chart view.
 * @param {SOURCE_HISTORIAN | SOURCE_TRADES} source - source of the data.
 * @returns {React.ReactElement} - chart view dropdown.
 */
const chartViewSelector = (
  dropdownOpen,
  toggle,
  chartView,
  setChartView,
  source,
) => {
  const { aggregateBy, groupBy, xAxisDomain } = chartView;
  const aggregateByProperty = aggregateBy === DATA_AGGREGATE_BY_PROPERTY;
  const aggregateByPortfolio = aggregateBy === DATA_AGGREGATE_BY_PORTFOLIO;
  const groupByCounterparty = groupBy === DATA_GROUP_BY_COUNTERPARTY;
  const groupByTradeType = groupBy === DATA_GROUP_BY_TRADE_TYPE;
  const xAxisDomainFixed = xAxisDomain === X_AXIS_DOMAIN_FIXED;
  const xAxisDomainDynamic = xAxisDomain === X_AXIS_DOMAIN_DYNAMIC;

  return (
    <Dropdown className="chart-view-selector" isOpen={dropdownOpen} toggle={() => toggle(VIEW_SELECTOR)}>
      <ViewWrapper className="btn btn-darken" caret>
        <FontAwesomeIcon icon={faChartColumn} size="1x" />
      </ViewWrapper>
      <DropdownMenu end>
        <DropdownItem header><FormattedMessage id="portfolio.portfolio_show.control.chart_selector.group_by.title" defaultMessage="Group by" /></DropdownItem>
        <DropdownItem
          disabled={source === SOURCE_HISTORIAN}
          onClick={() => setChartView({
            aggregateBy,
            groupBy: DATA_GROUP_BY_COUNTERPARTY,
            xAxisDomain,
          })}
        >
          <Label>
            <Input className="me-1" defaultChecked={groupByCounterparty} type="radio" name="chart_group_by" />
            <FormattedMessage id="portfolio.portfolio_show.control.chart_selector.group_by.option.counterparty" defaultMessage="Counterparty" />
          </Label>
        </DropdownItem>
        <DropdownItem
          disabled={source === SOURCE_HISTORIAN}
          onClick={() => setChartView(
            {
              aggregateBy,
              groupBy: DATA_GROUP_BY_TRADE_TYPE,
              xAxisDomain,
            },
          )}
        >
          <Label>
            <Input className="me-1" type="radio" defaultChecked={groupByTradeType} name="chart_group_by" />
            <FormattedMessage id="portfolio.portfolio_show.control.chart_selector.group_by.option.trade_type" defaultMessage="Trade type" />
          </Label>
        </DropdownItem>
        <DropdownItem header><FormattedMessage id="portfolio.portfolio_show.control.chart_selector.aggregate_by.title" defaultMessage="Aggregate by" /></DropdownItem>
        <DropdownItem onClick={() => setChartView({
          aggregateBy: DATA_AGGREGATE_BY_PORTFOLIO,
          groupBy,
          xAxisDomain,
        })}
        >
          <Label>
            <Input className="me-1" defaultChecked={aggregateByPortfolio} type="radio" name="chart_aggregate_by" />
            <FormattedMessage id="portfolio.portfolio_show.control.chart_selector.aggregate_by.option.portfolio" defaultMessage="Portfolio" />
          </Label>
        </DropdownItem>
        <DropdownItem onClick={() => setChartView({
          aggregateBy: DATA_AGGREGATE_BY_PROPERTY,
          groupBy,
          xAxisDomain,
        })}
        >
          <Label>
            <Input className="me-1" type="radio" defaultChecked={aggregateByProperty} name="chart_aggregate_by" />
            <FormattedMessage id="portfolio.portfolio_show.control.chart_selector.aggregate_by.option.property" defaultMessage="Property" />
          </Label>
        </DropdownItem>
        <DropdownItem header><FormattedMessage id="portfolio.portfolio_show.control.chart_selector.x_axis_domain.title" defaultMessage="X-Axis domain" /></DropdownItem>
        <DropdownItem onClick={() => setChartView({
          aggregateBy,
          groupBy,
          xAxisDomain: X_AXIS_DOMAIN_FIXED,
        })}
        >
          <Label>
            <Input className="me-1" defaultChecked={xAxisDomainFixed} type="radio" name="x_axis_domain" />
            <FormattedMessage id="portfolio.portfolio_show.control.chart_selector.x_axis_domain.option.fixed" defaultMessage="Fixed" />
          </Label>
        </DropdownItem>
        <DropdownItem onClick={() => setChartView({
          aggregateBy,
          groupBy,
          xAxisDomain: X_AXIS_DOMAIN_DYNAMIC,
        })}
        >
          <Label>
            <Input className="me-1" type="radio" defaultChecked={xAxisDomainDynamic} name="x_axis_domain" />
            <FormattedMessage id="portfolio.portfolio_show.control.chart_selector.x_axis_domain.option.dynamic" defaultMessage="Dynamic" />
          </Label>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default chartViewSelector;
