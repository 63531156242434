import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Platform Mode
export const PLATFORM_MODE_REBATE = 'PLATFORM_MODE_REBATE';
export const PLATFORM_MODE_TRADE = 'PLATFORM_MODE_TRADE';
export const PlatformModes = [
  PLATFORM_MODE_TRADE,
  PLATFORM_MODE_REBATE,
];

export const FEATURE_INHIBIT_COMMUNITY_TRADES = 'INHIBIT_COMMUNITY_TRADES';
export const FEATURE_INHIBIT_CONTRACTED_TRADES = 'INHIBIT_CONTRACTED_TRADES';
export const FEATURE_INHIBIT_NOMINATED_TRADES = 'INHIBIT_NOMINATED_TRADES';
export const Features = [
  FEATURE_INHIBIT_COMMUNITY_TRADES,
  FEATURE_INHIBIT_CONTRACTED_TRADES,
  FEATURE_INHIBIT_NOMINATED_TRADES,
];

// TODO: env for namespace [domain name reversed?]
export const ENOSI_USER_ID = 'enosi-user-id';
export const ENOSI_USER_AUTH_EXPIRY = 'enosi-user-auth-expiry';
export const ENOSI_USER_AUTH_TOKEN = 'enosi-user-auth-token';
export const ENOSI_USER_AUTH_TOKEN_ID = 'enosi-user-auth-token-id';
export const ENOSI_USER_AUTH_TOKEN_REFRESH = 'enosi-user-auth-token-refresh';
export const ENOSI_USER_AUTH_TOKEN_TYPE = 'enosi-user-auth-token-type';

// User Auth API relative paths
export const ENOSI_USER_AUTH_EMAIL_CONFIRM = 'auth/confirm';
export const ENOSI_USER_AUTH_REGISTER_CONFIRM = 'auth/confirm';
export const ENOSI_USER_AUTH_RESET_PASSWORD_CONFIRM = 'auth/confirm';
export const ENOSI_USER_AUTH_RESET_PASSWORD_REQUEST = 'reset-password';
export const AuthPaths = [
  ENOSI_USER_AUTH_EMAIL_CONFIRM,
  ENOSI_USER_AUTH_REGISTER_CONFIRM,
  ENOSI_USER_AUTH_RESET_PASSWORD_CONFIRM,
  ENOSI_USER_AUTH_RESET_PASSWORD_REQUEST,
];

// Enosi constants

// Syndesi
// Tariff Time Units
export const TARIFF_TIME_UNIT_UNSPECIFIED = 'TARIFF_TIME_UNIT_UNSPECIFIED';
export const TARIFF_TIME_UNIT_DAY = 'TARIFF_TIME_UNIT_DAY';
export const TARIFF_TIME_UNIT_MONTH = 'TARIFF_TIME_UNIT_MONTH';
export const TARIFF_TIME_UNIT_YEAR = 'TARIFF_TIME_UNIT_YEAR';
export const TariffTimeUnits = [
  TARIFF_TIME_UNIT_UNSPECIFIED,
  TARIFF_TIME_UNIT_DAY,
  TARIFF_TIME_UNIT_MONTH,
  TARIFF_TIME_UNIT_YEAR,
];
export const TariffTimeUnitNames = {
  [TARIFF_TIME_UNIT_DAY]: <FormattedMessage id="common.tariff_time_unit.day" defaultMessage="day" />,
  [TARIFF_TIME_UNIT_MONTH]: <FormattedMessage id="common.tariff_time_unit.month" defaultMessage="month" />,
  [TARIFF_TIME_UNIT_YEAR]: <FormattedMessage id="common.tariff_time_unit.year.label" defaultMessage="year" />,
};
// Tariff Types
export const TARIFF_TYPE_UNSPECIFIED = 'TARIFF_TYPE_UNSPECIFIED';
export const TARIFF_TYPE_ENERGY = 'TARIFF_TYPE_ENERGY';
export const TARIFF_TYPE_NETWORK = 'TARIFF_TYPE_NETWORK';
export const TARIFF_TYPE_ENVIRONMENTAL = 'TARIFF_TYPE_ENVIRONMENTAL';
export const TARIFF_TYPE_OTHER = 'TARIFF_TYPE_OTHER';
export const TariffTypes = [
  TARIFF_TYPE_UNSPECIFIED,
  TARIFF_TYPE_ENERGY,
  TARIFF_TYPE_NETWORK,
  TARIFF_TYPE_ENVIRONMENTAL,
  TARIFF_TYPE_OTHER,
];
// User Types
export const USER_TYPE_ADMIN = 'USER_TYPE_ADMIN';
export const USER_TYPE_CUSTOMER = 'USER_TYPE_CUSTOMER';
export const USER_TYPE_UNSPECIFIED = 'USER_TYPE_UNSPECIFIED';
export const UserTypes = [
  USER_TYPE_ADMIN,
  USER_TYPE_CUSTOMER,
];

// Trade
// Trade Direction
export const TRADE_DIRECTION_UNSPECIFIED = 'TRADE_DIRECTION_UNSPECIFIED';
export const TRADE_DIRECTION_BUY = 'TRADE_DIRECTION_BUY';
export const TRADE_DIRECTION_SELL = 'TRADE_DIRECTION_SELL';
export const TradeDirections = [
  TRADE_DIRECTION_BUY,
  TRADE_DIRECTION_SELL,
];
// Trade directions
export const TRADE_DIRECTIONS_LABEL = {
  buy: TRADE_DIRECTION_BUY,
  sell: TRADE_DIRECTION_SELL,
};

// Trade Type
export const TRADE_TYPE_CONTRACTED = 'TRADE_TYPE_CONTRACTED';
export const TRADE_TYPE_NOMINATED = 'TRADE_TYPE_NOMINATED';
export const TRADE_TYPE_COMMUNITY = 'TRADE_TYPE_COMMUNITY';
export const TRADE_TYPE_RESIDUAL = 'TRADE_TYPE_RESIDUAL';
export const TRADE_TYPE_UNSPECIFIED = 'TRADE_TYPE_UNSPECIFIED';
export const TradeTypes = [
  TRADE_TYPE_CONTRACTED,
  TRADE_TYPE_NOMINATED,
  TRADE_TYPE_COMMUNITY,
  TRADE_TYPE_RESIDUAL,
];

// DEPRECATED: not used for UI, but used in keys of data objects at this stage.
export const CONTRACTED = 'contracted';
export const NOMINATED = 'nominated';
export const COMMUNITY = 'community';
export const RESIDUAL = 'residual';

// Trade Point
// Trade Point Types
export const TRADE_POINT_TYPE_KNOWN = 'TRADE_POINT_TYPE_KNOWN';
export const TRADE_POINT_TYPE_COMMUNITY = 'TRADE_POINT_TYPE_COMMUNITY';
export const TRADE_POINT_TYPE_RESIDUAL = 'TRADE_POINT_TYPE_RESIDUAL';
export const TradePointType = [
  TRADE_POINT_TYPE_KNOWN,
  TRADE_POINT_TYPE_COMMUNITY,
  TRADE_POINT_TYPE_RESIDUAL,
];

// Trade Rules
// Trade Rule States
export const TRADE_RULE_STATE_UNSPECIFIED = 'TRADE_RULE_STATE_UNSPECIFIED';
export const TRADE_RULE_STATE_PROPOSED = 'TRADE_RULE_STATE_PROPOSED';
export const TRADE_RULE_STATE_ACCEPTED = 'TRADE_RULE_STATE_ACCEPTED';
export const TRADE_RULE_STATE_CANCELLED = 'TRADE_RULE_STATE_CANCELLED';
export const TRADE_RULE_STATE_REJECTED = 'TRADE_RULE_STATE_REJECTED';
export const TRADE_RULE_STATE_CLOSED = 'TRADE_RULE_STATE_CLOSED';
export const TradeRuleStates = [
  TRADE_RULE_STATE_PROPOSED,
  TRADE_RULE_STATE_ACCEPTED,
  TRADE_RULE_STATE_CANCELLED,
  TRADE_RULE_STATE_REJECTED,
  TRADE_RULE_STATE_CLOSED,
];

// DEPRECATED: not used for UI, but used in propTypes at this stage.
// TIME
// Day
export const DAY_MONDAY = 'MONDAY';
export const DAY_TUESDAY = 'TUESDAY';
export const DAY_WEDNESDAY = 'WEDNESDAY';
export const DAY_THURSDAY = 'THURSDAY';
export const DAY_FRIDAY = 'FRIDAY';
export const DAY_SATURDAY = 'SATURDAY';
export const DAY_SUNDAY = 'SUNDAY';
export const DAY_PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY';
export const Days = [
  DAY_MONDAY,
  DAY_TUESDAY,
  DAY_WEDNESDAY,
  DAY_THURSDAY,
  DAY_FRIDAY,
  DAY_SATURDAY,
  DAY_SUNDAY,
  DAY_PUBLIC_HOLIDAY,
];
export const DayNames = {
  [DAY_MONDAY]: 'Monday',
  [DAY_TUESDAY]: 'Tuesday',
  [DAY_WEDNESDAY]: 'Wednesday',
  [DAY_THURSDAY]: 'Thursday',
  [DAY_FRIDAY]: 'Friday',
  [DAY_SATURDAY]: 'Saturday',
  [DAY_SUNDAY]: 'Sunday',
  [DAY_PUBLIC_HOLIDAY]: 'Public Holiday',
};
// Month
export const MONTH_JANUARY = 'JANUARY';
export const MONTH_FEBRUARY = 'FEBRUARY';
export const MONTH_MARCH = 'MARCH';
export const MONTH_APRIL = 'APRIL';
export const MONTH_MAY = 'MAY';
export const MONTH_JUNE = 'JUNE';
export const MONTH_JULY = 'JULY';
export const MONTH_AUGUST = 'AUGUST';
export const MONTH_SEPTEMBER = 'SEPTEMBER';
export const MONTH_OCTOBER = 'OCTOBER';
export const MONTH_NOVEMBER = 'NOVEMBER';
export const MONTH_DECEMBER = 'DECEMBER';
export const Months = [
  MONTH_JANUARY,
  MONTH_FEBRUARY,
  MONTH_MARCH,
  MONTH_APRIL,
  MONTH_MAY,
  MONTH_JUNE,
  MONTH_JULY,
  MONTH_AUGUST,
  MONTH_SEPTEMBER,
  MONTH_OCTOBER,
  MONTH_NOVEMBER,
  MONTH_DECEMBER,
];
export const MonthNames = {
  [MONTH_JANUARY]: 'January',
  [MONTH_FEBRUARY]: 'February',
  [MONTH_MARCH]: 'March',
  [MONTH_APRIL]: 'April',
  [MONTH_MAY]: 'May',
  [MONTH_JUNE]: 'June',
  [MONTH_JULY]: 'July',
  [MONTH_AUGUST]: 'August',
  [MONTH_SEPTEMBER]: 'September',
  [MONTH_OCTOBER]: 'October',
  [MONTH_NOVEMBER]: 'November',
  [MONTH_DECEMBER]: 'December',
};

// AllTimes
// AllTimesOfDay
export const AllTimesOfDay = {
  start: { hours: 0, minutes: 0, seconds: 0 },
  finish: { hours: 24, minutes: 0, seconds: 0 },
};
// AllDaysOfWeek
export const AllDaysOfWeek = Days;
// AllMonthsOfYear
export const AllMonthsOfYear = Months;

// Frontend
// Listing
export const LIST_FILTER_ALL = 'LIST_FILTER_ALL';
// Trade Rule Actions
export const TRADE_RULE_ACCEPT = 'TRADE_RULE_ACCEPT';
export const TRADE_RULE_CANCEL = 'TRADE_RULE_CANCEL';
export const TRADE_RULE_CLOSE = 'TRADE_RULE_CLOSE';
export const TRADE_RULE_PROPOSE = 'TRADE_RULE_PROPOSE';
export const TRADE_RULE_REJECT = 'TRADE_RULE_REJECT';
export const TRADE_RULE_UNSPECIFIED = 'TRADE_RULE_UNSPECIFIED';
export const TradeRuleActions = [
  TRADE_RULE_ACCEPT,
  TRADE_RULE_CANCEL,
  TRADE_RULE_CLOSE,
  TRADE_RULE_PROPOSE,
  TRADE_RULE_REJECT,
];
// Active State
export const ACTIVE_STATE_ACTIVE = 'ACTIVE_STATE_ACTIVE';
export const ACTIVE_STATE_INACTIVE = 'ACTIVE_STATE_INACTIVE';
export const ACTIVE_STATE = 'active';
// Updating State
export const LOAD_MORE = 'LOAD_MORE';
export const REFETCH = 'REFETCH';

// Datepicker formatting.
export const DATEPICKER_DATE_FORMAT = 'yyyy/MM/dd';

// property data types
export const METER = 'METER';
export const TRADE = 'TRADE';

export const BUYER = 'buyer';
export const SELLER = 'seller';

// DEPRECATED: not used for UI, but used in keys of data objects at this stage.
export const BUY = 'buy';
export const SELL = 'sell';

export const DIRECTIONS = [BUY, SELL];

export const ELEC_ENERGY_CONSUMED = 'elec_energy_consumed';
export const ELEC_ENERGY_GENERATED = 'elec_energy_generated';

export const METRICS = {
  buy: ELEC_ENERGY_CONSUMED,
  sell: ELEC_ENERGY_GENERATED,
};

export const EXPORTS = 'exports';
export const IMPORTS = 'imports';

// chart constants
export const DATA_AGGREGATE_BY_PROPERTY = 'DATA_AGGREGATE_BY_PROPERTY';
export const DATA_AGGREGATE_BY_METER = 'DATA_AGGREGATE_BY_METER';
export const DATA_AGGREGATE_BY_PORTFOLIO = 'DATA_AGGREGATE_BY_PORTFOLIO';
export const DATA_GROUP_BY_COUNTERPARTY = 'DATA_GROUP_BY_COUNTERPARTY';
export const DATA_GROUP_BY_TRADE_TYPE = 'DATA_GROUP_BY_TRADE_TYPE';
export const X_AXIS_DOMAIN_FIXED = 'X_AXIS_DOMAIN_FIXED';
export const X_AXIS_DOMAIN_DYNAMIC = 'X_AXIS_DOMAIN_DYNAMIC';
export const SOURCE = 'SOURCE';
export const SOURCE_HISTORIAN = 'SOURCE_HISTORIAN';
export const SOURCE_TRADES = 'SOURCE_TRADES';
export const UNIT = 'UNIT';
export const UNIT_CURRENCY = 'UNIT_CURRENCY';
export const UNIT_ENERGY = 'UNIT_ENERGY';
export const UNIT_CARBON = 'UNIT_CARBON';
export const VIEW_SELECTOR = 'VIEW_SELECTOR';
export const DOWNLOAD_SELECTOR = 'DOWNLOAD_SELECTOR';
export const PROPERTY_SHOW_DEFAULT_VIEW = {
  aggregateBy: DATA_AGGREGATE_BY_PROPERTY,
  groupBy: DATA_GROUP_BY_COUNTERPARTY,
  xAxisDomain: X_AXIS_DOMAIN_FIXED,
};

export const PORTFOLIO_SHOW_DEFAULT_VIEW = {
  aggregateBy: DATA_AGGREGATE_BY_PORTFOLIO,
  groupBy: DATA_GROUP_BY_TRADE_TYPE,
  xAxisDomain: X_AXIS_DOMAIN_FIXED,
};
export const PORTFOLIO_USER_ROLE_OWNER = 'PORTFOLIO_USER_ROLE_OWNER';
export const PORTFOLIO_USER_ROLE_ADMIN = 'PORTFOLIO_USER_ROLE_ADMIN';
export const PORTFOLIO_USER_ROLE_STANDARD = 'PORTFOLIO_USER_ROLE_STANDARD';
export const PORTFOLIO_USER_ROLE_VIEWER = 'PORTFOLIO_USER_ROLE_VIEWER';
export const ALL_PORTFOLIO_USERS = 'ALL_PORTFOLIO_USERS';

export const PORTFOLIO_USER_ROLES = [
  PORTFOLIO_USER_ROLE_OWNER,
  PORTFOLIO_USER_ROLE_ADMIN,
  PORTFOLIO_USER_ROLE_STANDARD,
  PORTFOLIO_USER_ROLE_VIEWER,
];

export const CSV_DATA_TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const CSV_FILENAME_TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH-mm-ss";

export const DATA_PACK = 'DATA_PACK';

export const UNIT_ENERGY_ACRONYM = 'Wh';

export const UNIT_CARBON_EMISSIONS_ACRONYM = 'g-CO2•e';

export const CARBON = 'carbon';
export const FLAGS = 'flags';
export const IDENTIFIER = 'identifier';
export const VALUE = 'value';
export const VOLUME = 'volume';

export const TIME_ZONE_SYSTEM = 'system';
export const TIME_ZONE_UTC = 'utc';

/**
 * MIME type.
 * @constant {string}
 */

export const MIME_TYPE_CSV = 'text/csv';
export const MIME_TYPE_JSON = 'application/json';

// Feature flags
export const INHIBIT_CARBON_DATA_VIEWS = 'INHIBIT_CARBON_DATA_VIEWS';
export const INHIBIT_PORTFOLIO_VIEWS = 'INHIBIT_PORTFOLIO_VIEWS';

export const UNTRADED_ENERGY_KEY = 'untraded';

// Counterfactual constants
export const COST_BENEFIT_STATE_ICONS = {
  TRADE_VALUE_BUY_BENEFIT: <FontAwesomeIcon size="lg" color="green" icon={faCaretDown} />,
  TRADE_VALUE_BUY_COST: <FontAwesomeIcon size="lg" color="red" icon={faCaretUp} />,
  TRADE_VALUE_SELL_BENEFIT: <FontAwesomeIcon size="lg" color="green" icon={faCaretUp} />,
  TRADE_VALUE_SELL_COST: <FontAwesomeIcon size="lg" color="red" icon={faCaretDown} />,
  TRADE_VALUE_NEUTRAL: <FontAwesomeIcon size="sm" color="grey" icon={faCircleDot} />,
};

export const TRADE_VALUE_BUY_BENEFIT = 'TRADE_VALUE_BUY_BENEFIT';
export const TRADE_VALUE_BUY_COST = 'TRADE_VALUE_BUY_COST';
export const TRADE_VALUE_NEUTRAL = 'TRADE_VALUE_NEUTRAL';
export const TRADE_VALUE_SELL_BENEFIT = 'TRADE_VALUE_SELL_BENEFIT';
export const TRADE_VALUE_SELL_COST = 'TRADE_VALUE_SELL_COST';

// N/A Symbol.
export const NA_SYMBOL = '\u2014';

// chart aggregations
export const P1M1D = 'P1M1D';
export const P1M = 'P1M';
export const P1D = 'P1D';
export const PT30M = 'PT30M';
